<template>
  <div class="flex w-full items-center max-xl:flex-col md:gap-6">
    <div class="font-medium text-lexmea-blue-500 md:text-xl">
      {{ t("content") }}
    </div>
    <div class="flex w-40 md:w-60">
      <a
        href="https://www.ubiscore.com/privacy-badges/?ubiscore-id=6736296e9c0f7a1258293ec8"
        target="_blank"
        class="w-full"
        rel="noopener noreferrer"
      >
        <img :src="UbiscoreLogo" alt="ubiscore Logo" />
      </a>
      <a
        href="https://app.hoggo.io/trusthub/vendors/43079?embed_token=e749edfca3f3738bc22d482eb8acbe22891f2613cd2dd595464e2b3466a70517"
        target="_blank"
        class="w-full"
        ><img :src="GDPRBadge" alt="Trust Grade"
      /></a>
    </div>
  </div>
</template>
<script lang="ts" setup>
import UbiscoreLogo from "~/assets/landingpage/badges/ubiscore-logo.svg";
import GDPRBadge from "~/assets/landingpage/badges/gdpr-badge.webp";

const { t } = useI18n({
  useScope: "local",
});
</script>
<i18n>
{
  "de": {
    "content": "Unser Datenschutz wurde durch unabhängige Drittanbieter auf der höchsten Stufe zertifiziert:"
  },
  "en": {
    "content": "Our data protection has been certified to the highest level by independent third-party providers:"
  }
}
</i18n>
