<template>
  <div>
    <h2
      ref="testimonialsHeading"
      v-motion-lexmea-slide-top
      class="cards-testimonials px-3 text-4xl font-extrabold text-lexmea-blue-400"
    >
      {{ $t("info_pages.intro_page.testimonials_section.title") }}
    </h2>
    <p
      v-motion-lexmea-slide-top
      class="relative z-10 mb-5 px-3 text-base text-lexmea-blue-500 md:text-xl"
    >
      {{ $t("info_pages.intro_page.testimonials_section.description") }}
    </p>

    <div
      class="group relative mb-12 mt-12 grid h-[32rem] grid-cols-1 grid-rows-1 px-3 md:mb-24 md:mt-28 md:h-80"
    >
      <div
        class="col-start-1 col-end-1 row-start-1 row-end-1 h-[32rem] md:h-80"
      ></div>

      <transition name="fade-slide" mode="out-in">
        <div
          :key="testimonialIndex"
          class="col-start-1 col-end-1 row-start-1 row-end-1 flex flex-col gap-10 pb-4 md:h-full md:flex-row-reverse md:gap-16"
          @mouseover="pause"
          @mouseleave="resume"
        >
          <div class="flex flex-col justify-between">
            <p
              class="quote-background mb-8 text-balance text-lg font-medium italic text-lexmea-blue-300 md:mb-0 md:max-w-4xl md:text-2xl"
            >
              {{ rt(currentTestimonial.text) }}
            </p>
            <div class="text-lexmea-blue-200 md:mb-5">
              <div class="font-bold">
                {{ rt(currentTestimonial.author) }}
              </div>
              <div class="text-sm font-medium">
                {{ rt(currentTestimonial.position) }}
              </div>
            </div>
          </div>
          <figure>
            <img
              loading="lazy"
              class="w-40 object-contain sm:w-56"
              :src="currentTestimonial.picture"
              :alt="currentTestimonial.author"
            />
            <figcaption class="select-none text-4.5pt text-gray-300">
              {{ "© " + rt(currentTestimonial.picture_attribution) }}
            </figcaption>
          </figure>
        </div>
      </transition>

      <div
        class="absolute bottom-0 right-0 flex h-fit gap-2 opacity-0 transition-opacity group-hover:opacity-100"
      >
        <button
          class="h-fit rounded-full bg-lexmea-gray-500 p-2"
          @click="prevTestimonial"
        >
          <i-icon-park-outline-left class="h-4 w-4"></i-icon-park-outline-left>
        </button>
        <button
          class="h-fit rounded-full bg-lexmea-gray-500 p-2"
          @click="nextTestimonial"
        >
          <i-icon-park-outline-right
            class="h-4 w-4"
          ></i-icon-park-outline-right>
        </button>
      </div>
    </div>

    <SponsorLogos></SponsorLogos>
  </div>
</template>

<script setup lang="ts">
const { rt, tm } = useI18n();
import { useIntervalFn } from "@vueuse/core";

const testimonials = tm(
  "info_pages.intro_page.testimonials_section.testimonials"
);
const testimonialIndex = ref(0);

const testimonialsHeading = ref<HTMLElement>();

const currentTestimonial = computed(() => testimonials[testimonialIndex.value]);

const nextTestimonial = () => {
  testimonialIndex.value = (testimonialIndex.value + 1) % testimonials.length;
};

const prevTestimonial = () => {
  testimonialIndex.value =
    (testimonialIndex.value - 1 + testimonials.length) % testimonials.length;
};

const { pause, resume } = useIntervalFn(() => {
  nextTestimonial();
}, 8000);

const { cardsImageCSSTopValue } = useProductCardsScrollParallax(
  -35,
  testimonialsHeading,
  1.1
);
</script>

<style lang="scss" scoped>
.quote-background {
  z-index: 10;
  position: relative;

  &::before {
    content: "”";
    color: #dfeef5;
    position: absolute;
    display: inline-block;
    z-index: -1;
    top: 30px;
    transform: scale(10);
  }
}
/* Base transition for the whole testimonial */
.fade-slide-enter-active,
.fade-slide-leave-active {
  transition:
    opacity 0.5s ease,
    transform 0.5s ease;
}

/* Staggered fade and slide effects */
.fade-slide-enter-from {
  opacity: 0;
  transform: translateX(10%);
}

.fade-slide-leave-to {
  opacity: 0;
  transform: translateX(-10%);
}

/* Ensure incoming and outgoing elements don't overlap */
.fade-slide-leave-active {
  position: absolute;
}

.cards-testimonials {
  z-index: 10;
  position: relative;
  padding-right: 47px;

  &::before {
    content: url("~/assets/landingpage/bg-vectors/cards-funktionen.svg");
    position: absolute;
    display: inline-block;
    top: v-bind(cardsImageCSSTopValue);
    left: -8px;
    z-index: -1;
  }
}
</style>
